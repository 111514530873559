// https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  checkAllQuantityValid () {
    var hasProductErrorMsg = true

    document.querySelectorAll('.fa-exclamation-circle').forEach((obj) => {
      if (!obj.classList.contains('d-none')) {
        hasProductErrorMsg = false
      }
    })

    return hasProductErrorMsg
  }
}