export const MOMENT_FORMATS = {
    DATETIME: 'M/D/YY h:mm a',
    SERVER_FORMAT: 'YYYY-MM-DD HH:mm:ss Z',
    DATE: 'M/D/YY',
};

export const ORDER_STATUSES = {
    CREATED: 'created',
    REORDER: 're_order',
    CREATING: 'creating',
    DESIGN_REVISION: 'design_revision',
    CUSTOMER_REVISION: 'customer_revision',
    DENIED: 'denied',
    AWAITING_APPROVAL: 'awaiting_approval',
    AWAITING_PAYMENT: 'awaiting_payment',
    APPROVED: 'approved',
    IN_PROGRESS: 'in_progress',
    ORDER_GARMENTS: 'order_garments',
    RECEIVE_GARMENTS: 'receive_garments',
    ART_VECTORIZATION: 'art_vectorization',
    PRINTING: 'printing',
    READY_TO_SHIP: 'ready_to_ship',
    SHIPPED: 'shipped',
    REJECTED: 'rejected',
};