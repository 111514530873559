import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    this.toast = $(this.element).toast()
    
    this.toast.on('hidden.bs.toast', () => {
      this.toast.remove();
    });

    this.toast.toast('show');
  }
}