import { Controller } from "@hotwired/stimulus"
import { debounce } from 'underscore';
import $ from "jquery";

export default class extends Controller {
  static targets = [ 'listLink', 'priceFilter', 'subcategoryFilter', 'search', 'productModal', 'collapse' ];

  $productModal = null;

  initialize() {
    this.onSearch = debounce(this.onSearch, 300).bind(this);
  }

  connect() {
    this.$productModal = $(this.productModalTarget).modal({
      show: false,
      focus: false,
    });
  }

  openProductModal = e => {
    const productColorId = $(e.target).closest('a').attr('data-product-color-id')
    const list = $(this.productModalTargets)
    for (let element of list) {
      if ($(element).attr('data-product-color-id') === productColorId) {
        $(element).modal('show')
      }
    }
  }

  onSearch = () => this.onFilter()

  onFilter = () => {
    const priceFilter = this.priceFilterTarget.value;
    const query = this.searchTarget.value;
    const buildUrl = this.buildUrl();

    window.history.pushState("", "", buildUrl);
    
    $.ajax({
      method: 'GET',
      url: buildUrl,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        sort_by: priceFilter,
        query: query
      },
      dataType: 'script',
      success: () => {
        $('a#get-product-cards').trigger('ajax:success');
        $('#product-modal').trigger('darko:filtered');
      }
    })
  }

  buildUrl() {
    let url = $('a#get-product-cards').attr('href');
    const productCategories = $('input.product-category:checked').map((_idx, el) => $(el).val()).get();
    const brands = $('input.brand:checked').map((_idx, el) => $(el).val()).get();
    const bodyTypes = $('input.body-type:checked').map((_idx, el) => $(el).val()).get();

    if (productCategories.length > 0) {
      url = url + "/" + productCategories.join(",")
    }

    if (brands.length > 0) {
      url = url + "/brands/" + brands.join(",")
    }

    if (this.hasSubcategoryFilterTarget) {
      const subcategories = this.subcategoryFilterTarget.value;

      if (subcategories && productCategories.length == 1) {
        url = url + "/subcategories/" + subcategories
      }
    }

    if (bodyTypes.length > 0) {
      url = url + "/body-types/" + bodyTypes.join(",")
    }

    return url;
  }

  listLinkClick(e) {
    const $el = $(e.currentTarget);
    $('#product-select li.list-group-item').removeClass('list-group-item-action active');
    $el.parent('li.list-group-item').addClass('list-group-item-action active');
    $el.parent().parent().find('.list-group-item input[type="checkbox"]').prop('checked', true);
  }
}