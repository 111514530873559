import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'inputFile', 'inputResult', 'image'];

  onChange(_event) {
    this.startUpload()
  }

  showLoading () {
    this.spinner = document.createElement("i");
    this.spinner.classList.add('fas', 'fa-spinner', 'fa-spin');

    this.element.insertBefore(this.spinner, this.inputFileTarget.nextSibling);
  }

  hideLoading () {
    this.spinner.remove()
  }

  startUpload() {
    let file =  this.inputFileTarget.files[0]

    // validation START
    if (!!!file) {
      return
    }

    let fileSizeInKB = (file.size/1024); 
    let fileTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/bmp', 'image/gif', 'image/jpg']

    if(!fileTypes.includes(file.type.toLowerCase())){
      buildToast('Oops...', 'Your file type not support : ' + file.type, undefined, undefined, 'error');

      return
    }

    if(fileSizeInKB > 5120){
      buildToast('Oops...', 'Your file are too big', undefined, undefined, 'error');

      return
    }
    // validation END

    this.showLoading()

    fetch('/direct-uploaders/presign' + '?' + new URLSearchParams({ filename: file.name }))
      .then(response => response.json())
      .then(response => {
        this.uploadFile(response.url)
        
      }).catch((error) => {
        buildToast('Oops...', error, undefined, undefined, 'error');
        this.hideLoading()
      });
  }

  async uploadFile(presign_url) {
    let headers = {}
    let file =  this.inputFileTarget.files[0]
    headers['Content-Type'] = file.type
    
    await fetch(presign_url, {
      method: "PUT",
      body: file,
      headers,
    })
    .then(response => {
      let url = response.url.split('?')[0]

      this.inputResultTarget.value = url
      this.imageTarget.src = url
      this.hideLoading()
      this.inputResultTarget.dispatchEvent(new Event('change'));
    }).catch((error) => {
      buildToast('Oops...', error, undefined, undefined, 'error');
      this.hideLoading()
    });
  } 

  get url() {
    return '/direct-uploaders/presign'
  }
}