import { Controller } from "@hotwired/stimulus";
import { buildToast } from "../lib/utils";

export default class extends Controller {
  static targets = ["inputFile", "inputResult"];
  static values = {
    dropboxToken: String,
    userId: String,
  };

  onChange(_event) {
    this.startUpload();
  }

  startUpload() {
    this.initXhr();
    this.uploadFile();
  }

  uploadFile() {
    const file = this.inputFileTarget.files[0];
    this.xhr.open("POST", "https://content.dropboxapi.com/2/files/upload");
    this.xhr.setRequestHeader(
      "Authorization",
      "Bearer " + this.dropboxTokenValue,
    );

    if (this.userIdValue) {
      this.xhr.setRequestHeader("Dropbox-API-Select-User", this.userIdValue);
    }

    this.xhr.setRequestHeader("Content-Type", "application/octet-stream");
    this.xhr.setRequestHeader(
      "Dropbox-API-Arg",
      JSON.stringify({
        path: this.url + file.name,
        mode: "add",
        autorename: true,
        mute: false,
      }),
    );

    this.xhr.send(file);
  }

  initXhr() {
    this.xhr = new XMLHttpRequest();
    this.xhr.upload.addEventListener(
      "progress",
      this.uploadProgress.bind(this),
    );
    this.xhr.addEventListener("load", this.uploadCompleted.bind(this));
    this.xhr.addEventListener("error", this.uploadFailed.bind(this));
    this.xhr.addEventListener("abort", this.uploadCanceled.bind(this));
  }

  uploadProgress() {
    this.showLoading();
  }

  showLoading() {
    if (this.spinner) {
      return;
    }

    this.spinner = document.createElement("i");
    this.spinner.classList.add("fas", "fa-spinner", "fa-spin");

    this.element.insertBefore(this.spinner, this.inputFileTarget.nextSibling);
  }

  hideLoading() {
    this.spinner.remove();
  }

  uploadCompleted() {
    if (this.xhr.status === 200) {
      var fileInfo = JSON.parse(this.xhr.response);
      console.log(fileInfo);
      this.putImage(fileInfo);
    } else {
      var errorMessage = this.xhr.response || "Unable to upload file";
      buildToast("Oops...", errorMessage, 5000, false, "error");
    }

    this.hideLoading();
  }

  uploadFailed() {
    buildToast("Oops...", "An error occurred while uploading", "error");
    this.hideLoading();
  }

  uploadCanceled() {
    buildToast("Oops...", "Upload cancelled", "error");
    this.hideLoading();
  }

  putImage(fileInfo) {
    let url = fileInfo.path_lower;

    this.inputResultTarget.value = url;
    this.inputResultTarget.dispatchEvent(new Event("change"));
  }

  get url() {
    return "/shirtpop_weborders/";
  }
}
