import { Controller } from "@hotwired/stimulus"
import 'jquery-mask-plugin';

export default class extends Controller {
  connect() {
    $(this.element).mask('Y', {
        clearIfNotMatch: true,
        translation: {
          Y: { pattern: /[0-9]/, recursive: true }
        },
    });
  }
}