import { Controller } from "@hotwired/stimulus"
import {buildToast} from "../lib/utils";

export default class extends Controller {
    static targets = [ 'modal', 'submit', 'form', 'requiredInput' ];

    connect() {
      this.$modal = $(this.modalTarget).modal()

      if (this.hasFormTarget) {
        $(this.formTarget)
          .off('ajax:success')
          .on('ajax:success', () => {
              this.$modal.modal('hide');
              buildToast('Success', 'Art fee updated!');
          })
          .off('ajax:error')
          .on('ajax:error', () => {
              buildToast('Ooops...', 'An error occurred!', undefined, undefined, 'error');
          });
      }

      this.checkInputPassed()
    }

    checkInputChange = _e => {
      this.checkInputPassed()
    }

    checkInputPassed () {
      let allPassed = this.requiredInputTargets.every(input => input.value !== '')

      if (this.hasSubmitTarget) {
        $(this.submitTarget).prop('disabled', !allPassed);
      }
    }

    onSave (_e) {
      $(this.formTarget).find('input[type="submit"]')[0].click();
    }
}