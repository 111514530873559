import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'idInput', 'destroyInput', 'quantityInput' ];

    connect() {
        const id = this.idInputTarget.value;
        this.destroyInputTarget.value = (!id || !id.length) ? 1 : 0;
    }

    onQuantityChange = e => {
        let quantity = this.quantityInputTarget.value;
        if (typeof quantity === 'string') {
            if (quantity.length) quantity = parseInt(quantity);
            else quantity = 0;
        }
        if (quantity > 0) this.destroyInputTarget.value = 0;
        else this.destroyInputTarget.value = 1;
        
        $(e.target).closest('form').off('ajax:success')
            .on('ajax:success', e => {
                const [ ret ] = e.detail,
                    { data } = ret,
                    { item_category_quantity, secondary_items, order_design_item_quantities, order_design_id, total_price, average_price_per_piece, order_price, order_tax, min_quantity, product_color_id, product_color } = data || {},
                    orderDesignItemQuanIds = (order_design_item_quantities || []).map(({ id }) => id);
                const minQuantity = min_quantity ? min_quantity : 1
                const $quantityForm = $('form#compact-quantity-form')
                const $minQuantityMsg = $quantityForm.find(`.min-quantity[data-product-id="${product_color.product_id}"]`).parent()
                const $emptySizes = $quantityForm.find(`.invalid-product[data-product-color-id="${product_color_id}"]`).parent()
                const $product = $(`.product[data-product-id="${product_color.product_id}"][data-product-color-id="${product_color_id}"]`)
                const $orderDesign = $(`.order-details[data-design-id="${order_design_id}"]`)
                const $products = $orderDesign.find(`.product[data-product-id="${product_color.product_id}"]`)
                
                this.toggleValidationMessages($minQuantityMsg, $emptySizes, item_category_quantity, order_design_item_quantities, minQuantity, $orderDesign) 
                this.toggleErrorStates($product, $products, $orderDesign)
                this.updateInputValues($product, orderDesignItemQuanIds, order_design_item_quantities, $orderDesign)
                this.updateOrderPrice(order_price || '0', order_tax || '0');
                this.updateProductPrice(total_price, $product, $orderDesign)
                this.updateAveragePricePerPiece(average_price_per_piece, $product, $orderDesign)
                this.toggleCheckoutButton()
                this.updateItemsNumber($orderDesign)
                this.updateDesignPrices(secondary_items, $orderDesign)

            }).find('input[type="submit"]')[0].click();
    }

    updateDesignPrices(items, $orderDesign) {
        (items || []).forEach(({ average_price_per_piece, total_price, product_color }) => {
            let $product = $(`.product[data-product-id="${product_color.product_id}"][data-product-color-id="${product_color.id}"]`)
            this.updateProductPrice(total_price, $product, $orderDesign)
            this.updateAveragePricePerPiece(average_price_per_piece, $product, $orderDesign)
        })
    }

    updateInputValues = ($product, orderDesignItemQuanIds, order_design_item_quantities, $orderDesign) => {
        $orderDesign.find($product).find('input.id').each((idx, el) => {
            let id = $(el).val();
            if (id && id.length) id = parseInt(id);
            if (!orderDesignItemQuanIds.includes(id)){
                 $(el).val('');
            }
        });
        (order_design_item_quantities || []).forEach(({ id, product_size_id, quantity }) => {
            $orderDesign.find($product).find(`input.id[data-product-size-id="${product_size_id}"]`).val(id);
            $orderDesign.find($product).find(`input.quantity[data-product-size-id="${product_size_id}"]`).val(quantity);
        });
    }

    toggleValidationMessages = ($minQuantityMsg, $emptySizes, item_category_quantity, order_design_item_quantities, minQuantity, $orderDesign) => {
        if (typeof item_category_quantity === 'number' && typeof minQuantity === 'number') {
            if (item_category_quantity < minQuantity) {
                $orderDesign.find($minQuantityMsg).removeClass('d-none');
            }
            else { 
                $orderDesign.find($minQuantityMsg).addClass('d-none');
            }
        }

        if (!order_design_item_quantities.length){
            $orderDesign.find($emptySizes).removeClass('d-none');
        } else {
            $orderDesign.find($emptySizes).addClass('d-none');
        }
    }

    toggleErrorStates = ($product, $products, $orderDesign) => {
        const productColID = $product.find('.validation-messages p small').data('product-color-id')
        const $prodAlert = $(`.product[data-product-color-id="${productColID}"] .fa-exclamation-circle`)
        const $validationMsgs = $orderDesign.find($product).find('.validation-messages p')
        
        if (this.hasErrorMessages($validationMsgs)) {
            $orderDesign.find($prodAlert).removeClass('d-none')
        } else {
            $orderDesign.find($prodAlert).addClass('d-none')
        }

        $products.each((idx, obj) => {
            const prodColId = $(obj).data('product-color-id')
            if (prodColId !== productColID) {
                const $alert =  $(`.product[data-product-color-id="${prodColId}"] .fa-exclamation-circle`)
                const $validations = $(obj).find('.validation-messages p')
                if (this.hasErrorMessages($validations)) {
                    $alert.removeClass('d-none')
                }
                else {
                    $alert.addClass('d-none')
                }
            }
        })
    }

    hasErrorMessages = el => {
        var hasProductErrorMsg = false
        el.each((idx, obj) => {
            if (!$(obj).hasClass('d-none')) {
                hasProductErrorMsg = true
            }
        })
        return hasProductErrorMsg
    }

    updateItemsNumber($orderDesign) {
        var total = 0
        $orderDesign.find('.quantity.int').each((idx, obj) => {
            if($(obj).val()) {
                total = total + parseInt($(obj).val())
            }
        })
        $orderDesign.find('.items-nb').text(`(${total} pieces)`)
    }

    toggleCheckoutButton() {
      document.querySelector('#order-checkout').orderCheckout.toggleSubmit()
    }

    updateProductPrice = (price, el, $orderDesign) => {
        if (!price || !price.length) price = 0;
        const productPrice = `$${parseFloat(price).toFixed(2)}`
        $orderDesign.find(el).find('.order-design-item-price').text(productPrice)
    }

    updateAveragePricePerPiece = (price, el, $orderDesign) => {
        if (!price || !price.length) price = 0;
        const productPrice = `$${parseFloat(price).toFixed(2)} each |`
        $orderDesign.find(el).find('.order-design-item-price-per-piece').text(productPrice)
    }

    updateOrderPrice = (orderPrice, orderTax) => {
        if (!orderPrice || !orderPrice.length) return;
        if (orderPrice === '0') orderPrice = 0;
        if (orderTax === '0') orderTax = 0;

        const total_with_tax = `$${(parseFloat(orderPrice) + parseFloat(orderTax)).toFixed(2)}`
        const total = `$${parseFloat(orderPrice).toFixed(2)}`
        const tax = `$${parseFloat(orderTax).toFixed(2)}`

        $('.order-subtotal').text(total)
        $('.order-tax').text(tax)
        $('.total-price').text(total_with_tax)
    }
}