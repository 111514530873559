import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    this.reloadCloudflare()
  }

  reloadCloudflare() {
    turnstile.render(this.wrapperTarget, {
      sitekey: this.wrapperTarget.dataset.sitekey
    });
  }

  get wrapperTarget () {
    return document.querySelector('.cf-turnstile')
  }
}