import { ApplicationController, useDebounce } from "stimulus-use";

export default class extends ApplicationController {
  static debounces = ["autoSubmit"];
  static targets = ["submitButton"];

  connect() {
    useDebounce(this, { wait: 500 });
  }

  autoSubmit() {
    this.element.requestSubmit();
  }

  submitShowLoading() {
    this.submitButtonState(true);
  }

  submitButtonState(showLoading = true) {
    if (!this.submitButtonTarget) {
      return;
    }

    if (showLoading) {
      this.showLoadingSubmit();
    } else {
      this.hideLoadingSubmit();
    }
  }

  showLoadingSubmit() {
    this.spinnerSubmit = document.createElement("i");
    this.spinnerSubmit.classList.add("fas", "fa-spinner", "fa-spin");

    this.submitButtonTarget.prepend(this.spinnerSubmit);
  }

  hideLoadingSubmit() {
    if (!this.spinnerSubmit) {
      return;
    }

    this.spinnerSubmit.remove();
  }

  redirectAfterSubmit(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse;

      history.pushState(
        { turbo_frame_history: true },
        "",
        fetchResponse.response.url,
      );

      Turbo.visit(fetchResponse.response.url);
    }
  }
}
