// https://dev.to/leastbad/the-best-one-line-stimulus-power-move-2o90

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    
    this.toggleSubmit()
  }

  toggleSubmit () {
    let state = !document.querySelector('#order-details').orderDetails.checkAllQuantityValid()

    this.submitTarget.toggleAttribute('disabled', state)
  }
}