import { Controller } from "@hotwired/stimulus";
import { buildToast } from "../lib/utils";

export default class extends Controller {
  static values = {
    dropboxToken: String,
    userId: String,
  };

  connect() {
    if (!this.imageSrc) {
      return;
    }

    if (
      this.imageSrc.includes("amazonaws") ||
      this.imageSrc.includes("image/png") ||
      this.imageSrc.includes("image-placeholder")
    ) {
      return;
    }

    this.startShowing();
  }

  startShowing() {
    this.initXhr();
    this.downloadFile();
  }

  downloadFile() {
    const requestData = JSON.stringify({
      path: this.imageSrc,
    });
    this.xhr.open(
      "POST",
      "https://api.dropboxapi.com/2/files/get_temporary_link",
    );
    this.xhr.setRequestHeader(
      "Authorization",
      "Bearer " + this.dropboxTokenValue,
    );

    if (this.userIdValue) {
      this.xhr.setRequestHeader("Dropbox-API-Select-User", this.userIdValue);
    }

    this.xhr.setRequestHeader("Content-Type", "application/json");

    this.xhr.send(requestData);
  }

  initXhr() {
    this.xhr = new XMLHttpRequest();
    this.xhr.addEventListener("load", this.downloadComplete.bind(this));
    this.xhr.addEventListener("error", this.uploadFailed.bind(this));
    this.xhr.addEventListener("abort", this.uploadCanceled.bind(this));
  }

  uploadProgress() {
    // this.showLoading();
  }

  showLoading() {
    this.spinner = document.createElement("i");
    this.spinner.classList.add("fas", "fa-spinner", "fa-spin");

    this.element.insertBefore(this.spinner, this.inputFileTarget.nextSibling);
  }

  downloadComplete() {
    if (this.xhr.status === 200) {
      var fileInfo = JSON.parse(this.xhr.response);

      this.buildImageOrLink(fileInfo);
    } else {
      var errorMessage = this.xhr.response || "Unable to download file";
      console.log(errorMessage);
      buildToast("Oops...", errorMessage, 5000, false, "error");
    }
  }

  uploadFailed() {
    buildToast("Oops...", "An error occurred while uploading", "error");
  }

  uploadCanceled() {
    buildToast("Oops...", "Upload cancelled", "error");
  }

  buildImageOrLink(fileInfo) {
    let content;
    let link = fileInfo.link;
    let name = fileInfo.metadata.name;

    this.element.innerHTML = "";

    if (this.isImage(name)) {
      content = document.createElement("img");
      content.style.width = "100%";
      content.src = link;
    } else {
      content = document.createElement("a");
      content.innerHTML = name;
      content.setAttribute("href", link);
      content.setAttribute("_blank", "true");
    }

    this.element.appendChild(content);
  }

  isImage(url) {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];

    return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
  }

  get imageSrc() {
    return this.element.dataset.src;
  }
}
